<template>
  <div class="progress-bar">
    <v-progress-linear
      class="mt-3 rounded-lg"
      :value="Math.floor(percentage)"
      background-color="backgroundDark"
    ></v-progress-linear>
    <v-chip
      class="progress-value black--text"
      x-small
      :style="`left: ${progressValue}%`"
      color="primary"
    >
      {{ Math.floor(percentage) + ' %' }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percentage: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    progressValue() {
      if (Math.floor(this.percentage) <= 10) {
        return Math.floor(this.percentage);
      }
      if (Math.floor(this.percentage) <= 90) {
        return Math.floor(this.percentage) - 4;
      }
      return Math.floor(this.percentage) - 11;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  position: relative;
}
.progress-value {
  z-index: 3;
  position: absolute;
  top: -6px;
}
</style>
